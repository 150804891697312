<template>
  <div class="page">
    <Nav></Nav>
    <main class="home">
      <div class="main">
        <div class="wrap">
          <div class="news">
            <div class="list">
              <div class="list-item" v-for="(item, index) in dataList" :key="index" @click="goDetail(item.id)">
                <img :src="item.filename" :alt="item.title" class="cover" />
                <div class="content">
                  <div class="title">
                    <span> {{ item.name }} </span>
                  </div>
                  <div class="description">
                    {{ item.explain }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <foot></foot>
  </div>
</template>

<script>

// @ is an alias to /src
import Nav from "@/components/Nav.vue";
import foot from "@/components/foot.vue";

export default {
  components: {
    Nav,
    foot,
  },
  data() {
    return {
      itemId: 0,
      dataList: [],
    };
  },
  created() {
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1];
    let getqys = getqyinfo.split("&");
    let obj = {}; //创建空对象，接收截取的参数
    for (let i = 0; i < getqys.length; i++) {
      let item = getqys[i].split("=");
      let key = item[0];
      let value = item[1];
      obj[key] = value;
    }
    this.itemId = obj.id;
    this.loadData();
  },
  methods: {
    //请求数据
    loadData() {
      //分类列表
      this.api.getArticlesPage({
        id: this.itemId,
        pageIndex: 0,
        pageSize: 20
      }).then(res => {
        this.dataList = res;
      }).catch(err => {
        console.log(err)
      });
    },
    goDetail(id) {
      this.$router.push('/News?id=' + id);
    }
  },
};
</script>

<style scoped>
.main {
  background: #fff;
  margin-top: 50px;
}

.main .title {
  font-size: 32px;
  color: #333;
  font-weight: 700;
  text-align: center;
}

.main .tabs {
  margin-top: 50px;
  width: 100%;
  height: 450px;
}

.main .tabs .tab-item {
  width: 1200px;
  margin: 50px auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main .tabs .list-item:hover .content .description,
.main .tabs .list-item:hover .content .title {
  color: #dc1e32;
}

.main .tabs .list-item {
  cursor: pointer;
  object-fit: cover;
  width: 580px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  overflow: hidden;
  min-height: calc(100% - 16px);
}

.main .tabs .list-item img {
  width: 100%;
  height: 220px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}

.main .tabs .list-item .content {
  padding: 20px 20px 20px;
  text-align: center;
}

.main .tabs .list-item .content .title {
  color: #000;
  font-size: 18px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
  text-align: center;
}

.main .tabs .list-item .content .title>img {
  width: 10px;
  height: 15px;
}

.main .tabs .list-item .content .description {
  margin-top: 7px;
  font-size: 14px;
  color: #999;
  text-align: left;
}

.main .news {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}

.main .news .title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}

.main .news .title .title-main {
  font-size: 32px;
  color: #333;
  font-weight: 700;
}

.main .news .list .list-item .content .title span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  color: #333;
  font-weight: 700;
}

.main .news .title a {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 98px;
  height: 34px;
  line-height: 34px;
  border-radius: 16px;
  border: 1px solid #b5b5b5;
  font-size: 0.14rem;
  color: #666;
  text-align: center;
}

.main .news .list .list-item,
.main .news .list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5px;
}

.main .news .list {
  margin-top: 50px;
}

.main .news .list .list-item {
  cursor: pointer;
  width: calc(50% - 30px);
  height: 150px;
  margin-bottom: 2px;
  overflow: hidden;
  border-radius: 0.6px;
  background-color: #fff;
}

.main .news .list .list-item:hover .content .description,
.main .news .list .list-item:hover .content .title span {
  color: #fff;
}

.main .news .list .list-item:hover {
  background-color: #dc1e32;
}

.main .news .list .list-item .cover {
  width: 210px;
  height: 100%;
  overflow: hidden;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.main .news .list .list-item .content {
  width: 360px;
  padding: 15px 32px 15px 22px;
}

.main .news .list .list-item .content .description {
  margin-top: 0.12rem;
  font-size: 0.14rem;
  color: #666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.main .news .list .list-item .cover {
  width: 210px;
  height: 100%;
  overflow: hidden;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
</style>